@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// -----common----
.mat-radio-label,
.mat-checkbox-layout {
  font-weight: normal;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding-top: 8px;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field {
  width: 100%;
}


.iti {
  display: block !important;
  margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 32px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  // border-radius: 4px;
  font-size: 18px;
  background: #f7f9fc;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

ngx-intl-tel-input input {
  width: 100%;
}

.sort-css .mat-form-field-appearance-fill .mat-form-field-infix {
  padding-top: 0;
}

.sort-css {
  .mat-select-value {
    color: #1B54EB;
  }

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }

  .mat-select-arrow {
    color: #1B54EB;
  }
}

.mat-dialog-container {
  padding-top: 0 !important;
}

.files .folder-message {
  background: #fbfbfb;
  position: relative;
  overflow: hidden;
  padding: 40px 10px;
  box-shadow: 0 0 12px -5px #000;
  font-size: 1.4em;
  border: 5px solid #fbfbfb;
  text-align: center;
  margin: 15px;
  border-radius: 5px;
  transition: all .1s ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 0 15px;
  line-height: 34px;
}

.p-tree {
  height: 350px;
  overflow: auto;
}
///nebular Custom
.nb-theme-corporate
  nb-tabset{

      .tab-link{

        font-size: 1.2rem !important;
        outline: none !important;
      }

  }

  .user-container {
    position: relative;
    display: flex;
    align-items: center
  }

  .user-picture {
    position: relative;
    flex-shrink: 0
  }

  .user-picture.image {
    background-size: cover;
    background-repeat: no-repeat
  }

  .user-picture.initials {
    display: flex;
    align-items: center;
    justify-content: center
  }

  .user-name,
  .user-title {
    text-align: right
  }

  .info-container {
    margin-left: .5rem
  }

  .info-container {
    margin-right: .5rem
  }
  .header-container {
    display: flex;
    align-items: center;
    width: auto;
  }
.backdropBackground{
  background-color: #bbbbbbf2;
    backdrop-filter: blur(4px);
}
